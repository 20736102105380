import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, Listing, Wrapper, Title } from '../../components'
import website from '../../../config/website'
import LenderListing from '../../components/Listing/LenderListing'
import { Link } from 'gatsby'

const Hero = styled.header `
  background-image: url('/img/background-pattern.svg');
  background-color: #4675c3;
  background-position: bottom right;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const HeroInner = styled(Wrapper)`
  padding-top: 4rem;
  padding-bottom: 4rem;
  h1 {
    margin-bottom: 2rem;
    color: white,
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const HeroText = styled.div`
  font-size: 3.7rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  color: white,
  padding: '0.25em',
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2.369rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`

const IndexWrapper = Wrapper.withComponent('main')

class StudentLoan extends Component {
  render() {
    const {
      data: { homepage, social, posts, reviews, privateloans, projects },
    } = this.props
    return (
      <Layout>
        <Hero>
          <HeroInner>
            <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  color: 'white',
                  lineHeight: '1',
                  padding: '0.25em',
                }}
            >
              Student Loans</h1>
          </HeroInner>
        </Hero>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <h1 className="title has-text-centered">
                  <strong>Student Loans 101</strong>
                </h1>
                <div className="content" style={{ paddingTop: '1rem' }}>
                  <div className="subtitle">
                  Our team of analysts is continually researching the important questions students and parents have when it comes to refinancing their loans. We work hard to provide the most up to date and in-demand resources on student loans.
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <h3 className="title has-text-centered">
                  <strong>Resources</strong>
                </h3>
                <Link className="column resources" to="/student-loans/student-loan-forgiveness-programs">  Student Loan Forgiveness Programs</Link>
                <Link className="column resources" to="/student-loans/student-loan-forgiveness-for-nurses">Student Loan Forgiveness for Nurses</Link>
                <Link className="column resources" to="/student-loans/if-you-have-a-bad-credit-rating-refinancing-your-student">How to Refinance Student Loans With Bad Credit</Link>
                <Link className="column resources" to="/student-loans/10-benefits-of-consolidating-student-loans">10 Benefits of Consolidating Student Loans</Link>
              </div>
              <div className="column is-4">
                <h3 className="title has-text-centered">
                  <strong>Statistics</strong>
                </h3>
                <div className="content">

                  <article className="media">
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <strong>Avg. Student Loan Debt $38,900</strong>
                          <br></br>
                          <progress className="progress is-primary" value="90" max="100"></progress>
                        </p>
                      </div>
                    </div>
                  </article>

                  <article className="media">
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <strong>Avg. Debt Per Borrower $28,565</strong>
                          <br></br>
                          <progress className="progress is-primary" value="90" max="100"></progress>
                        </p>
                      </div>
                    </div>
                  </article>

                  <article className="media">
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <strong>Avg. Monthly Payment $320</strong>
                          <br></br>
                          <progress className="progress is-primary" value="75" max="100"></progress>
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>

        <IndexWrapper id={website.skipNavId} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <h2 className="title has-text-centered" style={{ marginBottom: '1rem' }}>Student Loan Refinance Reviews</h2>
          <LenderListing reviews={reviews.nodes} />
          <Title style={{ marginTop: '1rem', marginBottom: '1rem' }}>Recent Student Loan Articles</Title>
          <Listing posts={posts.nodes} />
        </IndexWrapper>
      </Layout>
    )
  }
}

export default StudentLoan

StudentLoan.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      }),
    }),
    social: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    reviews: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    privateloans: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query StudentLoanQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    social: allPrismicHeroLinksBodyLinkItem {
      nodes {
        primary {
          label {
            text
          }
          link {
            url
          }
        }
      }
    }
    reviews:   allPrismicLenderreview(filter: {tags: {in: "refinance"}, data: {categories: {elemMatch: {category: {slug: {eq: "student-loans"}}}}}}) {
    nodes {
      uid
      data {
        image {
          url
        }
        lender
        lenderafflink {
          url
        }
        categories {
          category {
            slug
            id
            document {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
  privateloans:   allPrismicLenderreview(filter: {tags: {in: "private"}, data: {categories: {elemMatch: {category: {slug: {eq: "student-loans"}}}}}}) {
    nodes {
      uid
      data {
        image {
          url
        }
        lender
        lenderafflink {
          url
        }
        categories {
          category {
            slug
            id
            document {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
    posts: allPrismicPost(filter: {data: {categories: {elemMatch: {category: {slug: {eq: "student-loans"}}}}}}, sort: { fields: [data___date], order: DESC }) {
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
          categories {
            category {
              slug
              document {
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProjectsBodyLinkItem {
      nodes {
        primary {
          label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`
